<template>
  <div>
    <datatable
      :url="entrees.url"
      :ajax="true"
      :ajaxPagination="true"
      :actions="entrees.actions"
      :columns="entrees.columns"
      :filters="entrees.filters"
      :query="entrees.query"
      :fillable="true"
      :selectable="true"
      :dropdown="false"
      sort="created_at"
      order="desc"
      ref="table"
    >
      <template #header>
        <div class="pt-4">
          <div class="flex gap-4 mb-5">
            <search-form
              placeholder="Search users names, emails, phone numbers etc."
              class="w-full"
              v-model="entrees.query"
              @submit="reloadTable"
            />
            <button class="btn btn-blue flex-shrink-0" @click="enrollUser">Enroll User</button>
          </div>
          <div class="flex items-start flex-wrap xl:flex-no-wrap">
            <div class="flex flex-wrap items-center w-full">
              <span class="processor-filter-title">Filters:</span>
              <template v-for="(filter, index) in entrees.filters">
                <span
                  v-html="filter.title"
                  :key="index"
                  class="processor-filter-item"
                  :class="{ active: currentFilter === filter }"
                  @click.prevent="toggleFilter(filter)"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
    </datatable>
    <FreeDataForm :key="key" ref="freeData" @success="reloadTable" />
  </div>
</template>

<script>
export default {
  components: {
   FreeDataForm: () => import('../../../../components/forms/promos/airtel/FreeData.vue'),
  },
  data() {
    return {
      key: 0,
      currentFilter: null,
      entrees: this.$options.resource([], {
        query: '',
        url: `${this.$baseurl}/admin/promos/airtel/free-data`,
        columns: [
          { th: 'UserID', name: 'user_id', align: 'right' },
          {
            th: 'Name',
            name: 'users.name',
            render: ({ user }) => user.name,
            sortable: false,
          },
          {
            th: 'Email',
            name: 'users.email',
            render: ({ user }) => user.email,
            sortable: false,
          },
          {
            th: 'Phone number',
            name: 'users.phone_no',
            render: ({ user }) => user.phone_no,
            sortable: false,
          },
          {
            th: 'Enrolled phone number',
            name: 'identifier',
            render: (row, identifier) => identifier,
            sortable: false,
          },
          {
            th: 'Date',
            name: 'created_at',
            render: ({ created_at }) =>
              this.$options.filters.dateFormat(created_at),
          },
        ],
        actions: [],
        filters: [],
      }),
    };
  },
  methods: {
    enrollUser() {
      this.$refs.freeData.open();
    },
    reloadTable() {
      this.key++;
      this.$refs.table.loadAjaxData();
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }

      this.$refs.table.clickedFilter(this.currentFilter);
    },
  },
};
</script>
